<template>
  <div class="card">
    <div class="card-header">
      <h3 class="card-title" style="font-size: 20px; font-weight: bold">
        อนุมัติโอนสินค้า : SA4-2
      </h3>
      <div class="card-toolbar">
        <!-- <button
          type="button"
          class="btn btn-sm btn-light btn-success btn-hover-scale me-2"
          style="font-size: 14px; font-weight: bold"
          @click="gotoImportExcel()"
        >
          นำข้อมูลเข้าจาก Excel
        </button> -->
        <!-- <button
          type="button"
          class="btn btn-sm btn-light btn-primary"
          style="font-size: 14px; font-weight: bold"
          @click="goToNew()"
        >
          + เพิ่มใบตัดสต๊อก
        </button> -->
      </div>
    </div>
    <!-- <div class="card-title row ms-5 me-5 mt-6">
      <div class="col-sm-3 mb-3">
        <input placeholder="ค้นหา" type="text" class="form-control" />
      </div>
      <div class="col-sm-1 text-end">
        <button
          type="button"
          class="btn btn-sm btn-light btn-primary"
          style="font-size: 14px; font-weight: bold"
        >
          ค้นหา
        </button>
      </div>
    </div> -->
    <div class="card-body">
      <div class="table-responsive">
        <table
          class="table table-hover table-rounded table-striped border gy-7 gs-7"
        >
          <thead>
            <tr
              style="width: 100%"
              class="fw-bold fs-5 text-gray-800 border-bottom-2 border-gray-200 mw-200px"
            >
              <th>เลขที่เอกสาร</th>
              <th>รหัส Transfer</th>
              <th>ชื่อ Transfer</th>
              <th>วันที่สร้างเอกสาร</th>
              <th>มูลค่า</th>
              <th>status</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>1</td>
              <td>100001</td>
              <td>ยาลดไข้ ตรา covid19</td>
              <td>ยาลดไข้ ตรา covid19</td>
              <td
                :style="
                  isApprove === null
                    ? 'cursor: pointer; color: orange'
                    : isApprove
                    ? 'cursor: ; color: green'
                    : 'cursor: pointer; color: red'
                "
              >
                {{
                  isApprove === null
                    ? "คำนวณเมื่ออนุมัติ"
                    : isApprove
                    ? "1234"
                    : "ไม่อนุมัติ จึงไม่คำนวณ"
                }}
              </td>

              <td
                @click="isApprove ? '' : handleApprove()"
                :style="
                  isApprove === null
                    ? 'cursor: pointer; color: orange'
                    : isApprove
                    ? 'cursor: ; color: green'
                    : 'cursor: pointer; color: red'
                "
              >
                <span v-if="isApprove === null">รออนุมัติโอนสินค้า</span>

                <span v-if="isApprove !== null">{{
                  isApprove ? "อนุมัติ" : "ไม่อนุมัติ"
                }}</span>
              </td>
              <td>
                <div>
                  <button
                    type="button"
                    class="btn btn-link btn-color-muted btn-active-color-success m-0 p-0"
                    @click="goToEdit()"
                  >
                    <i class="bi bi-printer-fill"><b class="mt-2 ms-1"> </b></i>
                  </button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <DialogConfirmByPass
      :dialogConfirmByPass="dialogConfirmByPass"
      @submitConfirmPass="submitConfirmPass"
      @closeDialogConformByPass="closeDialogConformByPass"
    />

    <div class="card-footer">
      <ul class="pagination">
        <li class="page-item previous disabled">
          <a href="#" class="page-link"><i class="previous"></i></a>
        </li>
        <li v-for="n in 5" :key="n" class="page-item">
          <a class="page-link">{{ n }}</a>
        </li>
        <li class="page-item next">
          <a href="#" class="page-link"><i class="next"></i></a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import DialogConfirmByPass from "../../components/dialogConfirmByPass.vue";
import Swal from "sweetalert2/dist/sweetalert2.js";

export default {
  components: {
    DialogConfirmByPass,
  },
  setup() {
    document.title = "bem-document-demo อนุมัติโอนสินค้า";
  },
  data: () => ({
    isApprove: false,
    dialogConfirmByPass: false,
  }),
  methods: {
    goToNew() {
      this.$router.push("/warehouse/WH6-1/new");
    },
    goToEdit() {
      this.$router.push({
        name: "WH4-edit",
        query: {
          id: "123",
        },
      });
    },
    gotoImportExcel() {
      this.$router.push("/warehouse/WH4/importexcel");
    },
    goToDelete() {},
    handleApprove() {
      this.dialogConfirmByPass = true;
    },
    submitConfirmPass() {
      this.isApprove = true;
      this.dialogConfirmByPass = false;
    },
    closeDialogConformByPass() {
      this.isApprove = false;
      this.dialogConfirmByPass = false;
    },
  },
};
</script>
